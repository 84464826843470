import qs from 'qs';
import axios from "axios";
import { message } from "antd";
import redux from '@/redux/store'
import loginOut from '@/utils/loginOut'

export default {
  baseURL: (() => {   // 判断是在生产环境还是开发环境，如果是生产环境baseURL为/api
    if (process.env.NODE_ENV === "development") {
      return '/api'
    } else {
      return "https://teacher.513.com/api/v1/"        //正式
      // return "http://teacher.prep.513.com/api/v1/"   //预发布
      // return "http://teacher.513online.top/api/v1/"  //测试
    }
  })(),

  ajaxs(getUrl, params, methods) {
    return new Promise((resolve, reject) => {
      let data = Object.assign({}, params);
      let axiosParams = {
        url: this.baseURL + getUrl,
        method: methods ? methods : "POST",
        timeout: 1000 * 60 * 5,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "token": redux.getState().teacherUserInfo ? redux.getState().teacherUserInfo.token : ""
        }
      }
      //添加参数
      if (methods === 'GET') {
        Object.defineProperty(axiosParams, 'params', {
          value: data,
          enumerable: true
        })
      } else if (methods === 'PUT') {
        Object.defineProperty(axiosParams, 'data', {
          value: data,
          enumerable: true
        })
      } else {
        Object.defineProperty(axiosParams, 'data', {
          value: data,
          enumerable: true
        })
      }
      axios(axiosParams).then(res => {
        if (res.data.code === 1) {
          resolve(res.data);
        } else if (res.data.code === 401) {
          // 退出登录状态
          loginOut(1)
        } else {
          message.error(res.data.msg);
          reject(res.data)
        }
      },
        err => {
          message.error("哎呀呀，后台系统出错啦！")
        }
      )
        .catch(err => {
          message.error("哎呀呀，前端系统出错啦！")
        });
    });
  },

  //文件上传
  uploadFile(getUrl, params, name = 'files') {
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append(name, params);
      let config = {
        url: this.baseURL + getUrl,
        data: param,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
      axios(config).then(
        res => {
          if (res.data.code === 1) {
            resolve(res.data);
          } else {
            // message.error(res.data.message);
            reject(res.data);
          }
        },
        err => {
          reject(err);
        }
      )
        .catch(err => {
          throw new Error(err);
        });
    });
  },

  // 下载全部文件
  downloadALL(url) {
    window.open(this.baseURL + url)
  },

  // 下载文件并改变文件后缀名
  changeFileName(url, filename) {
    download(url, filename);
    function download(url, filename) {
      getBlob(url, function (blob) {
        saveAs(blob, filename);
      });
    };

    function getBlob(url, cb) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    }

    function saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement('a');
        var body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };
    }
  }
};