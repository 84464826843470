const initValue = {
    uploading:[],
    finish:JSON.parse(localStorage.getItem('uploadFinishTask')) || [],
}
const uploadTask = (state = initValue,action) => {
    switch (action.type){
        // 更新上传任务
        case 'updateUploadTask':
            return {
                ...state,
                uploading:state.uploading.map(item=>{
                    if(item.id === action.item.id){
                        return action.item 
                    }
                    return item 
                })
            }
            // 添加上传任务
        case 'addUploadTask':
            return {
                ...state,
                uploading:[action.item,...state.uploading]
            }
            // 上传完成
        case 'uploadFinish':
            delete action.item.uploader;
            return {
                ...state,
                uploading:state.uploading.filter(item=>item.id != action.item.id),
                finish:[action.item,...state.finish].filter((item,index)=>index < 20)
            }
            // 删除上传记录
        case 'deleteTask':
            return {
                ...state,
                finish:state.finish.filter(item=>item.date !== action.date)
            }
        default:
        return state
    }
}
export default uploadTask;