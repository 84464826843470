import {combineReducers} from "redux";
import teacherUserInfo from './teacherUserInfoReducer'
import uploadTask from './uploadTaskReducer'

const reducers = combineReducers({
    teacherUserInfo,
    uploadTask
});

export default reducers;
