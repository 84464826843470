import React from "react"
import './loginModulePublic.css'
import { Link } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd';
import API from "@/api/api.js";
import phoneCode from '@/utils/phoneCode'
//登录
export default class SignIn extends React.Component {
    formRef = React.createRef();
	constructor(props) {
        super(props)
        this.state = {
            userPhoneFocus:0,
            passwordFocus:0,
            numberCodeFocus:0,
			phoneCodeTxt:"获取验证码"
        }
    }
    onFinish(values) {
        API.login.getBackPassword(values).then(res=>{
            message.success("修改成功！去登录",1,()=>{
                this.props.history.push('/login')
            })
        })
    };
    
    userPhoneFocusCu(e){
        this.setState({
            userPhoneFocus:e
        })
    };
    passwordFocusCu(e){
        this.setState({
            passwordFocus:e
        })
    };
    numberCodeFocusCu(e){
        this.setState({
            numberCodeFocus:e
        })
    };
    render() {
        return (
            <div className="SignInMain">
                <div className="SignInType">
                    <p>找回密码</p>
                </div>
                <div className="loginFrom">
                    <p className="modeTitle"><Link to="/login">去登录</Link></p>
                    <Form name="basic" ref={this.formRef} initialValues={{remember: true}} onFinish={(e)=>this.onFinish(e)} size="large">
                            <Form.Item label="" name="phone" rules={[{required: true,message: '请输入正确的手机号码',pattern:/^1[3456789]\d{9}$/ }]}>
                                <Input onFocus={()=>this.userPhoneFocusCu(1)} onBlur={()=>this.userPhoneFocusCu(0)} placeholder="请输入手机号码"
                                prefix={(<img src={require('@/assets/images/login/phone_default.png')}></img> )} />
                            </Form.Item>
                        
                            <Form.Item label="" name="verifyCode" rules={[{required: true, message: '请输入正确的验证码',pattern:/^[A-Za-z0-9]{4,6}$/ }]}>
                                <Input onFocus={()=>this.numberCodeFocusCu(1)} onBlur={()=>this.numberCodeFocusCu(0)} placeholder="请输入短信验证码"
                                prefix={(<img src={require('@/assets/images/login/securityCode_default.png')}></img>)}
                                suffix={(<div className="numberCodeBut">
                                <span onClick={()=>phoneCode(this, this.formRef.current,'2')}>{this.state.phoneCodeTxt}</span>
                            </div>)} />
                            </Form.Item>

                            <Form.Item label="" name="password" rules={[{required: true, message: '密码必须由6-20个字母和数字组成',pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/ }]}>
                                <Input.Password onFocus={()=>this.passwordFocusCu(1)} onBlur={()=>this.passwordFocusCu(0)} placeholder="请输入您的新密码"
                                prefix={(<img src={require('@/assets/images/login/password_show.png')}></img>)} />
                            </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">确认</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}