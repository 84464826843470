const initValue = JSON.parse(localStorage.getItem('teacherUserInfo'))
const teacherUserInfo = (state = initValue,action) => {
    switch (action.type){
        case 'teacherUserInfo':
            return Object.assign({},state,{teacherUserInfo:action.payload})
        default:
        return state
    }
}

export default teacherUserInfo;