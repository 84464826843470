import React from 'react';
import LoadableComponent from '@/utils/LoadableComponent'
import { Link } from 'react-router-dom';
import './contentMain.css'
import { Layout, Menu, Breadcrumb, ConfigProvider } from 'antd';
import loginOut from '@/utils/loginOut'
import '@/assets/font_icon/iconfont.css'
import CN from 'antd/es/locale/zh_CN';
import redux from '@/redux/store'

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

let Router = LoadableComponent(() => import('../Router'));
//三板块主页面
export default class ContentMain extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            thisPath: ['0'],
            titleData: [],
            openKeys: [],
            jurisdiction: redux.getState().teacherUserInfo && redux.getState().teacherUserInfo.roleName == 'train_teacher'
        };
        this.menuList = [
            {
                noRendering:true,
                icon: 'icon-xueshengguanli',
                text: "学生管理",
                path: '/studentManagement',
                children:[
                    {
                        text: "添加学员",
                        path: '/addStudent',
                    },
                    {
                        text: "学生信息",
                        path: '/studentInfo',
                    }
                ]
            },
            {
                icon: 'icon-shixunjiaoxue',
                text: "实训管理",
                path: '/teaching',
                children: [
                    {
                        text: "实训申请",
                        path: '/trainApplication',
                        children: [
                            {
                                text: "立即申请",
                                path: '/applyNow'
                            },
                            {
                                text: "实训详情",
                                path: '/trainDetail'
                            }
                        ]
                    },
                    {
                        text: "实训审核",
                        path: '/trainingReview',
                        // authentication: true
                    },
                    // {
                    //     text: "实训管理",
                    //     path: '/2'
                    // },
                    {
                        text: "实训教学",
                        path: '/trainTeaching',
                        // authentication: true, 
                        children: [
                            {
                                text: '作业列表',
                                path: '/passignment',
                                children: [{
                                    text: '批改作业',
                                    path: '/passignmentDetail',
                                }]
                            },
                            {
                                text: '考核列表',
                                path: '/assess',
                                children: [{
                                    text: '批改考核',
                                    path: '/assessDetail',
                                }]
                            },
                            {
                                text: '编辑排课',
                                path: '/schedule',
                            },
                            {
                                text: '编辑视频',
                                path: '/editVideo',
                            },
                            {
                                text: '上传任务',
                                path: '/uploadTask',
                            },
                        ]
                    },
                    // {
                    //     text: "实训跟踪",
                    //     path: '/trainingTracking',
                    //     children: [
                    //         {
                    //             text: '实训总进度',
                    //             path: '/overallProgress',
                    //         },
                    //     ]
                    // },
                    // {
                    //     text: "实训跟踪",
                    //     path: '/5'
                    // },
                    // {
                    //     text: "实训案例下载分享",
                    //     path: '/7'
                    // }
                ]
            },
            // {
            //     icon: 'icon-kechengguanli',
            //     text: "课程管理",
            //     path: '/8',
            //     children: [
            //         {
            //             text: "行业课程列表",
            //             path: '/9'
            //         },
            //         {
            //             text: "专业课程列表",
            //             path: '/10'
            //         }, {
            //             text: "课程进度管理",
            //             path: '/11'
            //         }, {
            //             text: "成绩管理",
            //             path: '/12'
            //         }, {
            //             text: "课程资料管理",
            //             path: '/13'
            //         }, {
            //             text: "题库",
            //             path: '/14'
            //         }
            //     ]
            // },
            // {
            //     icon: 'icon-jiuyetongji',
            //     text: "就业统计",
            //     path: '/15',
            //     children: [
            //         {
            //             text: "概况",
            //             path: '/16'
            //         },
            //         {
            //             text: "就业统计",
            //             path: '/17'
            //         }
            //     ]
            // },
            // {
            //     icon: 'icon-hangyebaogao',
            //     text: "行业报告",
            //     path: '/18',
            //     children: [
            //         {
            //             text: "行业统计报表",
            //             path: '/19'
            //         }
            //     ]
            // },
            // {
            //     icon: 'icon-jiaoyuanguanli',
            //     text: "教员管理",
            //     path: '/20',
            //     children: [
            //         {
            //             text: "教员管理",
            //             path: '/21'
            //         }
            //     ]
            // },
            // {
            //     icon: 'icon-xuexiaoguanli',
            //     text: "学校管理",
            //     path: '/schoolManagement',
            //     children: [
            //         {
            //             text: "学校信息",
            //             path: '/schoolInfo'
            //         },{
            //             text: "院系管理",
            //             path: '/facultyManagement'
            //         }
            //     ]
            // }
        ]
    }

    componentDidMount() {
        this.componentWillReceiveProps(this.props)
    }

    //初始化时匹配正确的导航栏与面包屑
    componentWillReceiveProps(nextProps) {
        let pathArr = nextProps.location.pathname.split('/');
        if (pathArr.length) {
            // 删除第一个空元素
            pathArr.shift();
        }
        this.setState({
            titleData: [],
            thisPath: []
        }, () => {
            this.detectionMenu(pathArr, this.menuList)
        })
    }

    //检测导航栏
    detectionMenu = (pathArr, menuList) => {
        let titleData = this.state.titleData;
        let thisPath = this.state.thisPath;
        let openKeys = this.state.openKeys
        if (pathArr.length && menuList && menuList.length) {
            menuList.forEach((item, index) => {
                if (pathArr[0] === item.path.replace("/", "")) {
                    titleData.push({
                        text: item.text,
                        path: item.path,
                    });
                    if (thisPath.length <= 1) {
                        if (thisPath.length) {
                            thisPath.push(`-${index.toString()}`)
                        } else {
                            openKeys.push(index.toString())
                            thisPath.push(index.toString())
                        }
                    }
                    this.setState({
                        titleData,
                        thisPath,
                        openKeys
                    }, () => {
                        pathArr.shift()
                        // 递归
                        this.detectionMenu(pathArr, item.children)
                    })
                    return;
                }
            })
        }
    }


    //导航栏
    renderMenu = () => {
        let dom = this.menuList.map((item, i) => {
            if (item.children && !item.noRendering) {
                return  !item.authentication || this.state.jurisdiction ?
                <SubMenu key={i} title={item.text} icon={<span className={`iconfont ${item.icon}`}></span>}>
                    {item.children.map((item2, j) => {
                        return  !item2.authentication || this.state.jurisdiction ?
                        <Menu.Item key={`${i}-${j}`}
                            onClick={() => { this.handleMenuClick(item.path + item2.path) }}>
                            {item2.text}
                        </Menu.Item> : null
                    })}
                </SubMenu> : null
            } else {
                return !item.authentication || this.state.jurisdiction ?
                <Menu.Item key={i}
                    onClick={() => { this.handleMenuClick(item.path) }}
                    icon={<span className={`iconfont ${item.icon}`}></span>}>
                    {item.text}
                </Menu.Item>
                : null
            }
        })
        return dom
    }

    //面包屑的叠加
    renderNavList = () => {
        let path = ''
        return this.state.titleData.map((item, index) => {
            path += item.path
            // if (index != 0 && index != this.state.titleData.length - 1) {
            //     return <Breadcrumb.Item key={index}>
            //         <Link to={path}>{item.text}</Link>
            //     </Breadcrumb.Item>
            // } else {
                return <Breadcrumb.Item key={index}>{item.text}</Breadcrumb.Item>
            // }
        })
    }

    //跳转页面
    handleMenuClick = (path) => {
        this.props.history.push(path);
    }


    render() {
        const {photo,schoolName,name} = redux.getState().teacherUserInfo||{}
        return (<Layout className="ContentMain" style={{ minHeight: '100vh' }}>
                <Sider style={{ 'background': '#3E4459' }}>
                    <div className="portrait">
                        <div className='headImg'>
                            <img src={photo?photo:require('@/assets/images/public/head_default.png')}></img>
                        </div>
                        <p>{name}</p>
                        <p>{schoolName}</p>
                    </div>
                    <Menu className="teacherMenu" theme="dark" selectedKeys={[this.state.thisPath.join('')]} mode="inline" style={{ "background": "none" }} defaultOpenKeys={this.state.openKeys}>
                        {this.renderMenu()}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-sub-header-background" style={{ padding: 0 }} >
                        <div className="logo">
                            <img src={require('@/assets/images/public/nav_logo.png')} />
                            <img src={require('@/assets/images/public/title.png')} />
                        </div>
                        <div className="userControl">
                            {/* <p>账户设置</p> */}
                            <p onClick={() => loginOut(0)}>退出登录</p>
                        </div>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb separator='>' style={{ margin: '16px 0' }}>
                            {this.renderNavList()}
                        </Breadcrumb>
                        <div className="site-layout-background" style={{ padding: '24px', minHeight: 'calc(100vh - 188px)', background: '#fff' }}>
                            <ConfigProvider locale={CN}>
                                <Router />
                            </ConfigProvider>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Copyright ©深圳市伍壹叁教育科技有限公司 版权所有 粤ICP备19029030号-1</Footer>
                </Layout>
            </Layout>)
    }
}