import React from 'react';
import './App.css';
import {Route,Switch} from 'react-router-dom';
import ContentMain from '@/routes/contentMain/contentMain';
import Login from '@/view/login/login'
function App() {
  return (
    <Switch>
      <Route path="/login" component={Login}></Route>
      <Route path="/" component={ContentMain}></Route>
    </Switch>
  );
}

export default App;