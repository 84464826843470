import React from "react"
import './loginModulePublic.css'
import { Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd';
import AJAX from "@/api/api";
import redux from '@/redux/store'
import { message } from "antd";
import {encryptBy} from "@/utils/encryption"
//登录
export default class SignIn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userPhoneFocus: 0,
            passwordFocus: 0,
            numberCodeFocus: 0,
            codeImg: null
        }
    }
    onFinish(values) {
        values = { ...values, verifyCodeId: this.state.codeImg.verifyCodeId, roleName: 'teacher' }
        values.password = encryptBy(values.password)
        AJAX.login.signIn(values).then(res => {
            message.success("登录成功", 1, () => {
                // 存token
                redux.dispatch({ type: "teacherUserInfo", payload: res.data })
                localStorage.setItem('teacherUserInfo', JSON.stringify(res.data))
                this.props.history.push('/')
            })
        }).catch(() => {
            this.getCodeImg()
        })
    };
    componentDidMount() {
        this.getCodeImg()
    }
    userPhoneFocusCu(e) {
        this.setState({
            userPhoneFocus: e
        })
    };
    passwordFocusCu(e) {
        this.setState({
            passwordFocus: e
        })
    };
    numberCodeFocusCu(e) {
        this.setState({
            numberCodeFocus: e
        })
    };
    getCodeImg() {
        AJAX.login.getCodeImgUrl().then(res => {
            this.setState({
                codeImg: res.data
            })
        })
    }
    render() {
        return (
            <div className="SignInMain">
                <div className="SignInType">
                    <p>教师登录</p>
                </div>
                <div className="loginFrom">
                    <p className="modeTitle">手机</p>
                    <Form name="basic" initialValues={{ remember: true }} onFinish={(e) => this.onFinish(e)} size="large">
                        <Form.Item label="" name="phone" rules={[{ required: true, message: '请输入正确的手机号码', pattern: /^1[3456789]\d{9}$/ }]}>
                            <Input onFocus={() => this.userPhoneFocusCu(1)} onBlur={() => this.userPhoneFocusCu(0)} placeholder="请输入手机号码"
                                prefix={(<img src={require('@/assets/images/login/phone_default.png')}></img>)} />
                        </Form.Item>

                        <Form.Item label="" name="password" rules={[{ required: true, message: '密码必须由6-20个字母和数字组成', pattern: /^(\w){6,20}$/ }]}>
                            <Input.Password onFocus={() => this.passwordFocusCu(1)} onBlur={() => this.passwordFocusCu(0)} placeholder="请输入密码"
                                prefix={(<img src={require('@/assets/images/login/password_show.png')}></img>)} />
                        </Form.Item>

                        <Form.Item label="" name="verifyCode" rules={[{ required: true, message: '请输入正确的图片验证码', pattern: /^[A-Za-z0-9]{4,6}$/ }]}>
                            <Input onFocus={() => this.numberCodeFocusCu(1)} onBlur={() => this.numberCodeFocusCu(0)} placeholder="请输入图片验证码"
                                prefix={(<img src={require('@/assets/images/login/securityCode_default.png')}></img>)}
                                suffix={(<div className="numberCodeImg">
                                    <img onClick={() => this.getCodeImg()} src={this.state.codeImg && `data:image/png;base64,${this.state.codeImg.base64Str}`}></img>
                                </div>)} />
                        </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit">登录</Button>
                        </Form.Item>
                    </Form>
                    <Link className="controlPassword" to="/login/getBackPassword">忘记密码</Link>
                    {/* <div className="otherWaysTitle">
                        <p>其他登录方式</p>
                    </div>
                    <ul className="otherWays">
                        <li><Link >企业登录</Link></li>
                        <li><Link >学生登录</Link></li>
                    </ul> */}
                </div>
            </div>
        )
    }
}