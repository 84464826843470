import Ajax from "./request"

export default {
    login:{
        signIn(params){  // 登录
            return Ajax.ajaxs("/user/login", params);
        },
        signUp(params){   // 注册
            return Ajax.ajaxs("/user/register", params);
        },
        getBackPassword(params){   // 找回密码
            return Ajax.ajaxs("/user/reset/password", params);
        },
        getCodeImgUrl(){   // 图片验证码
            return Ajax.ajaxs("/verifyCode", {},'GET');
        },
        getPhoneCode(params){   //短信验证码
            return Ajax.ajaxs("/sendMobileCode", params);
        },
        logOut(){              //退出登录
            return Ajax.ajaxs("/user/logout", {});
        }
    },
    manager:{
        addStudent(params){    //添加学生
            return Ajax.ajaxs("student/add",params);
        },
        getStudentList(params){    //学生列表
            return Ajax.ajaxs("/student/list",params);
        },
        getStudentDetail(userId){    //单个学生信息
            return Ajax.ajaxs(`/student/detail/${userId}`,{},'GET');
        },
        reviseStudentInfo(params){    //修改学生信息
            return Ajax.ajaxs(`/student/update`,params);
        },
        getAllSchool(){             //学校列表
            return Ajax.ajaxs("/school/list/all", {},'GET');
        },
        getSchoolAllFaculty(params){  //学校所有系
            return Ajax.ajaxs("/school/faculty/list/teacher", params);
        },
        getSchoolAllMajor(params){  //学校所有专业
            return Ajax.ajaxs("/school/major/list", params);
        },
        getSchoolAllClass(params){  //学校所有班级
            return Ajax.ajaxs("/school/class/list", params);
        },
        getCurTeacherFaculty(params){  //当前老师下所有系
            return Ajax.ajaxs("/school/faculty/list/teacher/class", params);
        },
        getCurTeacherMajor(params){  //当前老师下所有专业
            return Ajax.ajaxs("/school/major/list/teacher", params);
        },
        getCurTeacherClass(params){  //当前老师下所有班级
            return Ajax.ajaxs("/school/class/list/teacher", params);
        },
    },
    teaching:{
        getRecommendedPracticeList(params){         // 根据类别id获取对应的推荐实训
            return Ajax.ajaxs("/train/recommends", params);
        },
        getMyTrainingApplication(params){         // 我的实训申请
            return Ajax.ajaxs("/train/apply/studentList", params,'GET');
        },
        applyNow(params){         // 实训申请
            return Ajax.ajaxs("/train/manager/apply", params);
        },
        getTrainDetail(params){         // 实训详情
            return Ajax.ajaxs(`/train/detail/${params.id}`, {},'GET');
        },
        getTrainList(params){               //获取实训列表
            return Ajax.ajaxs("/train/trainList", params);
        },
        getWorkList(params){                //获取实训作业列表
            return Ajax.ajaxs("/work/manager/list", params);
        },
        getManagerList(params){             //获取学生作业列表
            return Ajax.ajaxs('/work/manager/answer/list',params);
        },
        workMark(params){                   //学生作业评分/批改
            return Ajax.ajaxs('/work/manager/answer/update',params);
        },
        examinationMark(params){                   //学生考核评分/批改
            return Ajax.ajaxs('/examination/manager/answer/update',params);
        },
        whetherWorkAllDownload(params){           //学生作业是否有可用下载
            return Ajax.ajaxs('/work/manager/checkDownloadFlag',params,'GET');
        },
        workAllDownload(trainMergeId,trainId,workId){            //学生作业全部下载
            return Ajax.downloadALL(`/work/manager/batch/download/workAnswer?trainMergeId=${trainMergeId}&trainId=${trainId}&workId=${workId}`);
        },
        whetherExaminationAllDownload(params){           //学生考核是否有可用下载
            return Ajax.ajaxs('/examination/manager/checkDownloadFlag',params,'GET');
        },
        examinationAllDownload(trainMergeId,trainId,examinationId){     //学生考核全部下载
            return Ajax.downloadALL(`/examination/manager/batch/download/examinationAnswer?trainMergeId=${trainMergeId}&trainId=${trainId}&examinationId=${examinationId}`);
        },
        getExaminationList(params){         //获取实训考核列表
            return Ajax.ajaxs("/examination/manager/list", params);
        },
        getExaminationManagerList(params){  //获取学生考核列表
            return Ajax.ajaxs('/examination/manager/answer/list',params);
        },
        examinationkMark(params){            //学生考核评分/批改
            return Ajax.ajaxs('/examination/manager/answer/update',params,);
        },
        getTrainTeacherApplyList(params){   //实训老师申请列表
            return Ajax.ajaxs('/train/apply/trainStudentList',params,'GET');
        },
        checkApplyTrainGetTeacherList(params){      //检查合并实训 返回老师信息
            return Ajax.ajaxs('/train/checkApplyTrainGetTeacher',params);
        },
        combinedTraining(params){      //合并实训
            return Ajax.ajaxs('/train/merge',params);
        },
        rejectAudit(params){      //拒绝审核
            return Ajax.ajaxs('/train/refuse',params,'PUT');
        },
        getSchedulelist(params){        //根据实训班级id查询排期
            return Ajax.ajaxs(`/train/trainChapterScheduling/${params}`,{},'GET');
        },
        downLoadTest(params){        //下载测试接口
            return Ajax.ajaxs(`/work/manager/batch/download/workAnswer/test`,{},'GET');
        },
        addTrainChapterScheduling(params){        //添加实训排期
            return Ajax.ajaxs(`/train/addTrainChapterScheduling`,params);
        },
        getTrainChapter(params){        //获取实训章节
            return Ajax.ajaxs(`/trainChapter/getChapterInfo/${params}`,{},'GET');
        },
    },
    file:{
        upload(params){                             // 单个上传文件
            return Ajax.uploadFile("/file/upload",params);
        },
        uploads(params,name='files'){                             // 上传多个文件
            return Ajax.uploadFile("/student/add/batch",params,name);
        },
        download(url){                              // 下载文件
            return Ajax.ajaxs("/file/download",{fileUrl:url});
        },                                    // 修改文件名并下载文件
        downloadAndChangeFileName(url, filename){
            Ajax.changeFileName(url, filename);
        },
        createUploadVideo(params){
            return Ajax.ajaxs(`/video/createUploadVideo`,params);
        },
        uploadEmploymentInfo(params){
            return Ajax.uploadFile(`/employment/batchImport`,params,'file');
        }
    }
}